.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 71vh;
}

@media screen and (max-width: 600px) {
    .loader {
        height: 72vh;
    }
}