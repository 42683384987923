.weatherBoard {
    display: flex;
    background: green;
    overflow: hidden;
    height: 100%;
}

@media screen and (max-width: 600px) {
    .weatherBoard {
        flex-direction: column;
    }
}