body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.search {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    grid-gap: 20px;
    gap: 20px;
}

.search > div {
    flex: 1 1;
}


@media screen and (max-width: 600px) {
    .search {
        flex-direction: column;
    }
}
.searchSection {
    height: 7vh;
    border: 1px solid red;
}

@media screen and (max-width: 600px) {
    .searchSection {
        height: 15vh;
    }
}
.defaultCities {
    border: 2px solid red;
}


.columnItem {
    -webkit-user-select: none;
            user-select: none;
    padding: 16px;
    margin: 0 0 8px 0;
    height: 150px;
}

[data-dragging="true"] {
    background: red
}

[data-dragging="false"] {
    background: #456C86
}

@media screen and (max-width: 600px) {
    .columnItem {
        height: 120px;
    }
}

.column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    margin: 4px;
    background: darkgrey;

}
.weatherBoard {
    display: flex;
    background: green;
    overflow: hidden;
    height: 100%;
}

@media screen and (max-width: 600px) {
    .weatherBoard {
        flex-direction: column;
    }
}
.footer {
    height: 7vh;
    border: 2px solid black;
}
.resultSearchedCity {
    border: 2px solid black;
    height: 100%;
}

/* this is where grid starts */
.searchedCityLeftSide {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
}

.searchedCityAllDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.searchedCityMainDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.currentWeatherIcon {
    width: 140px;
    display: flex;
    justify-self: end;


}

.currentTemperature {
    display: flex;
    justify-self: start;
    align-self: center;
}

.temperatureChoice {
    display: flex;
    justify-self: start;
    align-self: start;
}

@media screen and (max-width: 600px) {

    .searchedCityAllDetails {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .currentWeatherIcon {
        height: 12vh;
        width: 12vh;

    }


    .searchedCityMainDetails {
        width: 100%;
    }
}
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 71vh;
}

@media screen and (max-width: 600px) {
    .loader {
        height: 72vh;
    }
}
.navigation {
    display: flex;
    justify-content: space-between;
    height: 9vh;
    border: 1px solid red;
}

@media screen and (max-width: 600px) {
    .navigation {
        height: 8vh;
    }
}
.loginregister{
    display: flex;
    justify-content: space-around;
}

.button-small {
    height: 25px;
}

.center {
    display: flex;
    align-self: center;
}

.indexPage {
    display: grid;
    background: yellow;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 2vh;
    height: 100vh;
}

.globalContainer {
    padding: 0 2vh 0 2vh;
}

@media screen and (max-width: 600px) {
    .globalContainer {
        padding: 0 1vh 0 1vh;
    }
}

.globalCitiesContainer {
    height: 68vh;
    padding-bottom: 2vh;
}

@media screen and (max-width: 600px) {
    .globalCitiesContainer {
        height: 970px;
    }
}

