.search {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 20px;
}

.search > div {
    flex: 1;
}


@media screen and (max-width: 600px) {
    .search {
        flex-direction: column;
    }
}