.navigation {
    display: flex;
    justify-content: space-between;
    height: 9vh;
    border: 1px solid red;
}

@media screen and (max-width: 600px) {
    .navigation {
        height: 8vh;
    }
}