.globalContainer {
    padding: 0 2vh 0 2vh;
}

@media screen and (max-width: 600px) {
    .globalContainer {
        padding: 0 1vh 0 1vh;
    }
}

.globalCitiesContainer {
    height: 68vh;
    padding-bottom: 2vh;
}

@media screen and (max-width: 600px) {
    .globalCitiesContainer {
        height: 970px;
    }
}
